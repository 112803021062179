.ion-page#main{
  overflow: clip;
  padding:
      env(safe-area-inset-top, 20px)
      env(safe-area-inset-right, 0px)
      env(safe-area-inset-left, 0px)
      env(safe-area-inset-bottom, 0px);
  background-color: #003E66;
}

ion-content, ion-header, ion-modal{
  user-select: none;
}

.sidebarStyle {
  display: inline-block;
  position: absolute;
  top: 10;
  left: 0;
  margin: 12px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;
  font-family: SSENRegular;
}
   
.mapContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #005628;
}

#mapTypeDialog {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 1%;
  bottom: 120px;
  min-width: 220px;
  padding: 10px;
  border-radius: 14px;
  background: var(--ion-color-secondary);
  border: 1px solid white;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 6px;
  opacity: 1;
  transition: 0.5s;
}

.noCookies div#mapTypeDialog {
  bottom: 92px;
  left: 10px;
}

.noCookies #googleAnalyticsMode {
  bottom: 55px;
  left: 65px;
}

.noCookies div.mapboxgl-ctrl-bottom-left {
  left: 0;
}

.noCookies #mapTypeContainer {
  left: 10px;
  bottom: 32px;
}

#mapTypeDialog.hide {
  left: -220px !important;
}

#mapTypeDialog>div {
  display: flex;
  width: 100%;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

#mapTypeDialog div img {
  height: 45px;
  width: 45px;
  border-radius: 10px 0 10px 0;
  border: 1.5px solid white;
  transition: 0.1s;
}

#mapTypeDialog div ion-label {
  color: #ffffff;
  font-size: 1.1em;
  font-family: 'SSENRegular';
  padding: 8px 10px;
  margin: 0 0 0 15px;
  border-radius: 15px;
  transition: 0.1s;
}

#mapTypeDialog div.active img {
  height: 55px;
  width: 55px;
  border: 3px solid white;
}

#mapTypeDialog div.active ion-label {
  font-family: 'SSENBold';
  background: #ffffff20;
}

div#areaFilterItem{
	display: flex;
	align-items: center;
	/* background-color: #ffffff10; */
	/* margin: 3px 15px 3px 15px; */
	padding: 8px;
	border-radius: 25px;
  }
  
  div#areaFilterItem:first-child{
	margin-top: 10px;
  }
  
  /* div#areaFilterItem ion-icon{
	height: 32px;
	width: 25px;
	padding-left: 10px;
	padding-top: 0;
  } */
  
  div#areaFilterItem ion-label{
	height: auto;
	text-align: left;
	margin: 0;
	padding-bottom: 0;
	padding-top: 0;
	font-family: SSENRegular;
	flex-grow: 1;
  }
  
  div#areaFilterItem ion-toggle{
	padding-top:4px;
	padding-bottom:4px;
	min-width: 36px;
  }

.mapboxgl-ctrl-geocoder {
  margin: 18px 0 0 15px !important;
  background-color: #ffffffe8 !important;
  border-radius: 14px !important;
}

.mapboxgl-ctrl-geocoder input:focus {
  outline:none;
}

.mapboxgl-ctrl-geocoder input[type='text'] {
  font-family: SSENRegular;
  font-size: 17px;
  --placeholder-opacity: 1;
}

.mapboxgl-ctrl-geocoder ul{
  font-family: SSENRegular;
}

.mapboxgl-ctrl-geocoder--icon-search{
  color: #003E66;
  margin-left: 3px;
}

.mapbox-gl-geocoder--error{
  font-family: SSENRegular;
}

.mapbox-gl-geocoder--no-results{
  font-family: SSENRegular;
}

.mapboxgl-ctrl-geocoder--icon-search {
  filter: brightness(0) saturate(100%) invert(9%) sepia(72%) saturate(6621%) hue-rotate(197deg) brightness(101%) contrast(104%);
}

.mapboxgl-ctrl-geocoder--pin-right button {
  background: none;
}

.mapboxgl-ctrl-geocoder .suggestions {
  background-color: #ffffffe8 !important;
  border-radius: 14px !important;
}

/* Deal with attribution*/
.mapboxgl-ctrl-attrib{
  height:23px !important;
  font-family: SSENRegular;
}

.mapboxgl-ctrl-attrib-button{
  display: none;
}

.mapboxgl-ctrl-attrib-inner{
  padding-top: 2px;
}

/* .geocoder-dropdown-item {
  padding: 5px;
} */

/* .geocoder-dropdown-text {
  font-family: SSENRegular;
} */


/* .geocoder-dropdown-icon {
  margin-right: 10px;
  color: #003E66;
} */

.mainMapTypeLabel{
  margin-top: -17px;
  margin-left: -10px;
}

ion-radio{
--color: white;
margin-top:  -9px;
width: 15px;
height: 15px;
}

.radio-md .radio-icon, .radio-wp .radio-icon{
  border-radius: unset !important;
  }

ion-radio-group#mainMapType{
  position: absolute;
  /* transition: 0.2s; */
  width: 180px;
  top: 20px;
  right: 10px;
  background-color: #003E66;
  height: 30px;
  border-radius: 10px 10px 10px 10px;
}

.mainMapTypeItem{
 --ion-background-color: #003E66;
 height:25px;
 margin-top: -4px;
 font-size: 12px;
 font-family: SSENRegular;
}

.splitLeft{
  position: absolute;
  z-index: 1;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  top: 70px;
  left:0;
  width: calc(100% - 370px);
  /* transition: 0.2s; */
}

.splitLeft.fullscreen{
  width: 100%;
}

.splitRight.closed{
  visibility: hidden;
}

.pageHeader{
  position: absolute;
  height: 70px;
  background-color: #ffffff;
  box-shadow: 0px -15px 20px 5px #222428;
  width: 100%;
  border: #003E66 8px solid;
  border-left: none;
  border-right: none;
}

.pageHeader ion-img {
  height: 54px;
  width: 350px;
  position: absolute;
  top: 0;
  right: 0;
}

.pageHeader ion-button {
  margin-top: 14px;
  margin-left: 10px;
  width: 145px;
}

.pageHeader ion-button ion-icon {
  margin-right: 5px;
}

.pageHeader ion-button span {
  margin-top: 2px;
}

.pageTitle {
  position: absolute;
  width: 100%;
  color: #003E66;
  font-family: SSENBold;
  font-weight: bold;
  text-align: center;
  font-size: 1.2em;
}

.ios-pageHeader{
  position: absolute;
  height: 50px;
  background-color: #ffffff;
  box-shadow: 0px -15px 20px 5px #222428;
  width: 100%;
  border: #003E66 8px solid;
  border-left: none;
  border-right: none;
}

.ios-pageHeader ion-img {
  height: 54px;
  width: 100%;
  position: absolute;
  top:0;
}

.ios-pageHeader ion-button {
  margin-top: 2px;
  margin-left: 10px;
  width: 140px;
}

.ios-pageHeader ion-button ion-icon {
  margin-right: 5px;
}

#devMode {
  display: flex;
  position: absolute;
  left: calc(1% + 55px);
  bottom: 30px;
  padding-left: 5px;
  padding-right: 5px;
  height: 20px;
  color: rgb(255 0 0 / 62%);
  background-color: #ffffff8a;
  font-weight: bold;
  z-index: 9999;
  /* pointer-events: none; */
  align-items: center;
  border-radius: 10px;
}

.noCookies #devMode {
  bottom: 32px;
  left: 65px;
}

#devModeDot {
  bottom: 15px;
  width: 11px;
  height: 11px;
  background: red;
  border-radius: 100px;
}

#devModeText {
  bottom: 0px;
  margin: 0;
  margin-left: 5px;
  font-size: 15px;
  display: none;
}

#devModeDot:hover + #devModeText {
  display: inline;
}

#googleAnalyticsMode {
  display: flex;
  position: absolute;
  left: calc(1% + 55px);
  bottom: 53px;
  padding-left: 5px;
  padding-right: 5px;
  height: 20px;
  color: rgb(255 0 0 / 62%);
  background-color: #ffffff8a;
  font-weight: bold;
  z-index: 9999;
  /* pointer-events: none; */
  align-items: center;
  border-radius: 10px;
}

#googleAnalyticsModeDot {
  bottom: 30px;
  width: 11px;
  height: 11px;
  background: blue;
  border-radius: 100px;
}

#googleAnalyticsModeText {
  bottom: 0px;
  margin: 0;
  margin-left: 5px;
  font-size: 15px;
  display: none;
}

#googleAnalyticsModeDot:hover + #googleAnalyticsModeText {
  display: inline;
}

.mapKey{
  position: absolute;
  right: 10px;
  top: 18px;
  cursor: pointer;
  background: #003e66;
  border-radius: 14px;
  z-index: 99;
}

.mapKeyH2 {
  font-size: 1em;
  font-family: SSENRegular;
  text-align: center;
  color: #fff;
  background-color: var(--ion-color-primary);
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: 0;
  border-radius: 14px 14px 0 0;
  padding-left: 20px;
  margin-top: 0;
}

.show-filter-from-mapkey{
  position: absolute;
  left: 4px;
  top: -1px;
  color: #fff;
  height: 21px;
  background-color: transparent;
  --box-shadow: none;
  border: solid white 2px;
  font-family: 'SSENRegular';
  padding: 1px;
  padding-top: 2px;
  border-radius: 25px;
  /* font-weight: bold; */
  width: 85px;
  --background: none;
}

ion-label#show-filter-label-from-mapkey{
  position: relative;
  left: -15px;
  top: 2.5px;
}

ion-icon#show-filter-icon-from-mapkey{
  position: absolute;
  right: -6px;
  top: -0.2px;
  height: 13px;
}

.mapKeyH2 ion-icon {
  float: right;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.keyGroups {
  display: flex;
  flex-direction: row;
  min-width: 240px;
}

.keyGroup {
  padding-top: 3px;
  padding-bottom: 3px;
}

ion-item#mapKeyItem {
  --ion-item-background: transparent;
  height: 35px;
  font-family: SSENRegular;
  display: flex;
  padding-bottom: 5px;
}

ion-icon#mapKeyIcon{
  background-color: transparent;
  height: 28px;
  padding-top: 5px;
}

ion-label#mapKeyLabel {
  background-color: transparent;
  height: auto;
  width: auto;
  padding: 15px 0 10px 10px;
  font-size: 14px;
  font-family: SSENRegular;
  color: white;
}

.mapKeyClosed{
  /* transition: 0.2s; */
  position:absolute;
  right: 0px;
  top: 0px;
}

.keyButtonOnMap{
  position:absolute; 
  right:10px;
  top:15px;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  --ion-color-secondary-contrast: white;
  /* --ion-color-secondary-contrast: #629C49; */
  --padding-start: 7px;
  --padding-end: 7px;
}

.grayscale {
  filter: grayscale(1);
}

.evSearchButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
  bottom: 0;
  position: absolute;
}

.close{
  color: white;
  border: solid white 2px;
  font-family: 'SSENRegular';
  /* background-color: #629C49; */
  background-color: var(--ion-color-primary);
  position: absolute;
  right: 5px;
  top: 8px;
  padding: 2px 10px 3px 10px;
  border-radius: 25px;
  font-weight: bold;
}

.close span {
  margin-right: 5px;
}

h2#noData{
  font-size:10pt;
  margin-left: 55px;
  margin-top: 10px;
  font-family: 'SSENRegular';
}

ion-button#show-report-from-notification{
  --border-color: none; 
  --border-width: 0px !important;
}

.tsandcsContentScroll {
	background-color: var(--ion-color-secondary);
	overflow-y: auto;
	overflow-x: hidden;
	padding-bottom: 20px;
	height: calc(100%);
}

ion-modal#tsandcstrue {
  font-family: 'SSENRegular';
  --background: #0f4170;
  text-align: justify;
  --backdrop-opacity: 0.8 !important;
  --border-radius: 10px;
  --height: 30em !important;
  --width: 45em !important;
  position: absolute;
  /* user-select: all;  */
}

ion-modal#tsandcstrue a#link {
	transition: 0.1s;
	color: white !important;
	text-decoration: underline !important;
}

ion-modal#tsandcstrue a#link:hover {
	color: var(--ion-color-primary-tint) !important;
}

ion-modal#tsandcstrue h3{
  color: white;
  text-align: center;
}

ion-modal#tsandcstrue div#agree{
  color: white;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
  font-size: 10pt;
}

ion-modal#tsandcstrue div#terms{
  color: white;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
  font-size: 10pt;
}

ion-modal#tsandcstrue div#note{
  color: white;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
  font-size: 8pt;
}

ion-modal#tsandcstrue ion-button {
  width: 15em;
  height: 3em;
  margin: 1em auto;
  flex: none;
  margin:10px;
}

ion-modal#tsandcstrue div#buttons {
  text-align: center;
}

ion-modal#tsandcsfalse {
  font-family: 'SSENRegular';
  --background: #0f4170;
  text-align: justify;
  --backdrop-opacity: 0.8 !important;
  --border-radius: 10px;
  --height: 16em !important;
  --width: 30em !important;
  position: absolute;
  /* user-select: all;  */
}

ion-modal#tsandcsfalse h3{
  color: white;
  text-align: center;
  font-size: 14pt;
}

ion-modal#tsandcsfalse div#agree{
  color: white;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
  font-size: 11pt;
  text-align: center;
}


ion-modal#tsandcsfalse a#link {
	transition: 0.1s;
	color: white !important;
	text-decoration: underline !important;
}

ion-modal#tsandcsfalse a#link:hover {
	color: var(--ion-color-primary-tint) !important;
}

ion-modal#tsandcsfalse div#terms{
  color: white;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
  font-size: 10pt;
}

ion-modal#tsandcsfalse div#note{
  color: white;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
  font-size: 8pt;
}

ion-modal#tsandcsfalse ion-button {
  width: 15em;
  height: 3em;
  margin: 1em auto;
  flex: none;
  margin:10px;
}

ion-modal#tsandcsfalse div#buttons {
  text-align: center;
}


#licencesContainer {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
}

.licenceContainer {
  display: flex;
  flex-direction: column;
  background-color: #ffffff10;
  border-top: #ffffff30 1px solid;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.licenceDetails {
  margin-bottom: 5px;
}

.extendedLicenceDetails {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 10px;
}

.licenceEntry {
  display: flex;
  flex-direction: column;
  width: 130px;
  background: #fff1;
  border-radius: 12px;
  padding: 4px 0;
}

.licenceEntry span:first-of-type {
  font-weight: bold;
}

.licenceEntry a {
  color: white;
  transition: 0.1s;
  font-size: 1.2em;
}

.licenceEntry a:hover {
  color: #629c49 !important;
}


ion-button#associatedFault {
  height: 30px;
  width: 30px;
  font-size: 5px;
  margin: 0em auto;
}

ion-icon#assIcon{
  color:white;
  background-color: var(--ion-color-primary);
  /* background-color: #629C49; */
  margin: 0em auto;
  font-size: 20px;
}

ion-row#timeline{
  font-size: 1em;
  font-family: SSENRegular;
  margin: auto;
}

ion-row#timeline{
  font-size: 1em;
  font-family: SSENRegular;
  margin: auto;
}

.timelineSmall{
  margin: auto;
  margin-top: 15px;
  padding-right: 10px;
  white-space: nowrap;
}

.notification{
  position:absolute;
  left: 385px;
  top: 45px;
  right:unset;
  bottom:unset;
  font-size: 1em;
  font-family: SSENRegular;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 0px;
  border-radius: 14px 14px 0px 0px;
  z-index: 9999;
}

ion-button#generalInformationOnMap{
  width: 30px;
  height: 30px;
  --ion-color-secondary-contrast: white;
  vertical-align: middle;
  --padding-start: 5px;
  --padding-end: 0px;
}

.summaryButtonOnMap{
  position:absolute; 
  right:10px;
  bottom: 400px;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  --ion-color-secondary-contrast: white;
  --padding-start: 6px;
  --padding-end: 6px;
}

.filterButtonOnMap{
  position:absolute; 
  right:10px;
  bottom:365px;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  --ion-color-secondary-contrast: white;
  --padding-start: 7px;
  --padding-end: 7px;
}

.downloadButtonOnMap{
  position:absolute; 
  right:10px;
  bottom:330px;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  --ion-color-secondary-contrast: white;
  --padding-start: 6px;
  --padding-end: 6px;
}

.acronymButtonOnMap{
  position: absolute;
  right: 10px;
  bottom:295px;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  --ion-color-secondary-contrast: white;
  --padding-start: 0px;
  --padding-end: 13px;
  --padding-top: 2px;
  font-size: 2.5em;
}

.evButtononMap{
  position:absolute; 
  right:10px;
  bottom:250px;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  --ion-color-secondary-contrast: white;
  --padding-start: 5px;
  --padding-end: 5px;
  --padding-bottom: 3px;
  font-size: 2.5em;
}

.zoomOutButtononMap{
  position:absolute; 
  right:10px;
  bottom:205px;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  --ion-color-secondary-contrast: white;

  --padding-start: 6px;
  --padding-end: 6px;
}

.threedButtonOnMap{
  position:absolute; 
  right:10px;
  bottom:170px;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  --ion-color-secondary-contrast: white;
  font-weight: 900;
  font-size: 1em;
  --padding-start: 6px;
  --padding-end: 6px;
}


.toolTips{
  font-family: SSENRegular;
} 

.mapboxgl-ctrl-group{
  background: #003E66;
  margin: 0 10pt 10pt 0 !important;
}

.mapboxgl-ctrl-icon{
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(100%) hue-rotate(0deg) brightness(100%) contrast(100%);
}

ion-label#startReport {
  background-color: transparent;
  height: auto;
  width: auto;
  font-family: SSENRegular;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

ion-icon#startReport{
  font-size: 1.6em;
  padding: 0;
  margin: 0;
}

div#sidebarExpander {
  position: absolute;
  right: 0;
  top: 38%;
  width: 25px;
  height: 50px;
  border-radius: 20px 0px 0px 20px;
  background-color: #003E66;
  border-left: 1.5px solid white;
  border-bottom: 1.5px solid white;
  border-top: 1.5px solid white;
  color:white;
  cursor: pointer;
  transition: 0.1s;
  z-index: 999;
}

div#sidebarExpander:hover {
  background-color: #003E66;
  width: 28px;
}

div#sidebarExpander ion-icon {
  width: 23px;
  height: 100%;
  margin: auto;
  padding-left: 2px;
}

.mapboxgl-popup {
  border-radius: 10px 10px 10px 10px;
  max-width: 400px;
  max-height: 30px;
  font: 12px/20px SSENBold, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  color:white;
  }

  .mapboxgl-popup-content {
    border-radius: 10px 10px 10px 10px;
    background-color: var(--ion-color-secondary);
  }

  .mapboxgl-popup-tip {
    border-top-color: var(--ion-color-secondary);
  }

  .mapboxgl-popup-content h6 {
    margin: 0;
    display: block;
    font: 10px;
    margin-top: 5px;
    font-size: small;
  }

  /* .mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
    border-bottom-color: #91c949;
  }  */

  /* .mapboxgl-popup-close-button {
    display: none;
  }
  
  /* .mapboxgl-popup-content-wrapper {
    padding: 1%;
  }
  
  .mapboxgl-popup-content h3 {
    background: #91c949;
    color: #fff;
    margin: 0;
    display: block;
    padding: 10px;
    border-radius: 3px 3px 0 0;
    font-weight: 700;
    margin-top: -15px;
  }
  
  .mapboxgl-popup-content h4 {
    margin: 0;
    display: block;
    padding: 10px;
    font-weight: 400;
  }
  
  .mapboxgl-popup-content div {
    padding: 10px;
  }
  
  .mapboxgl-container .leaflet-marker-icon {
    cursor: pointer;
  }
  
  .mapboxgl-popup-anchor-top > .mapboxgl-popup-content {
    margin-top: 0px;
  }
*/


/* .searchBar{
  position: absolute;
  left: 5px;
  top: 75px;
  width: 370px;
  height: 50px;
  font-family: SSENRegular;
} */

/* .searchBar ion-searchbar .searchbar-input-container .searchbar-input{
  background-color: #FFFFFFE8;
  color: #707070;
  --placeholder-opacity: 1;
  border-radius: 10px;
}

.searchBar ion-searchbar .searchbar-input-container .searchbar-search-icon{
  color: #003E66;
} */

/* .container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
} */

.centerLoadingIndicator{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loadingIndicator{
  margin-top: 50px;
  margin-bottom: 15px;
  width: 150px;
  height: 150px;
}

#mapLoadingIndicatorContainer{
  z-index: 99;
  position: relative;
  width: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  background: rgba(0,62,102,0.9);
  border-radius: 15px;
  padding: 10px;
  top: calc(50% - 102px);
  font-family: SSENRegular;
}

#mapLoadingIndicatorContainer.reportMapLoadingIndicator{
  margin-top: 70px;
}

@media only screen and (max-width: 515px) {

  #mapLoadingIndicatorContainer.reportMapLoadingIndicato{
      margin-top: 110px;
  }

}

#mapLoadingIndicatorContainer .loadingIndicator{
  margin: 0;
}

#mapLoadingIndicatorContainer h4{
  margin-top: 0;
}

#mapTypeContainer {
  position: absolute;
  bottom: 65px;
  left: 1vw;
  height: 50px;
  width: 50px;
  cursor: pointer;
  transition: 0.1s;
  border: 2px solid rgb(255,255,255);
  border-radius: 10px 2px 10px 2px;
}

#mapTypeContainer img:hover {
  filter: contrast(1);
}

#mapTypeContainer img {
  filter: contrast(1.2);
  border-radius: 10px 2px 10px 2px;
}

div.mapboxgl-ctrl-bottom-left {
  left: calc(1vw + 48px);
}

div #rotationWarningContainer {
  display: none;
}

.mapToast {
  transform: translateY(-85px) translateX(-175px);
  --max-width: 266px;
  text-align: center;
  --background: #003E66;
  --border-radius: 20px;
  --border-color: var(--ion-color-primary);
  --border-style: solid;
  --border-width: 2px;
}


.mapToastFull {
  transform: translateY(-85px) !important;
}


@media only screen and (max-width: 940px) {
  .mapToast {
      transform: translateY(-85px) translateX(-175px);
  }
}

@media only screen and (max-width: 810px) {
  .mapToast {
      transform: translateY(-60px) translateX(-175px);
  }

  .mapToastFull {
    transform: translateY(-60px) !important;
  }
}

@media only screen and (max-width: 640px) {
  .mapToast {
      transform: translateY(-35px);
      --max-width: none;
      --start: 65px;
      --end: 55px;
  }

  .mapToastFull {
    transform: translateY(-35px) !important;
  }

}

@media only screen and (max-height: 450px) and (min-width: 480px) and (orientation:landscape) {

  div #rotationWarningContainer {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: white;
    background-color: #003E66;
    z-index: 9999;
  }

  div #rotationWarningContainer.hidden {
    display: none;
  }

  div #rotationWarningContainer #rotationWarningClose {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  div #rotationWarningContainer #rotationWarningContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  div #rotationWarningContainer #rotationWarningContent img {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
  }

  div #rotationWarningContainer #rotationWarningContent p,
  div #rotationWarningContainer #rotationWarningContent h3 {
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
  }

}

 /* MOBILE STYLES */

@media only screen and (max-width: 1260px) {

  .keyGroups {
    flex-direction: column;
  }

  .keyGroup {
    border-top: 1px #ffffff50 dashed;
  }

  .keyGroup:first-of-type {
    border-top: none;
  }

}

 @media only screen and (max-height: 680px) {

  div#sidebarExpander{
    top: 10% !important;
  }

  ion-radio-group#mainMapType {
    right: 50px;

}

  .summaryButtonOnMap  {
    top: 60px;
    bottom: 0;
    left: 15px;
    right: 0;
  }

  .filterButtonOnMap {
    top: 60px;
    bottom: 0;
    left: 50px;
    right: 0;
  }

  .downloadButtonOnMap {
    top: 60px;
    bottom: 0;
    left: 85px;
    right: 0;
  }
  
  .acronymButtonOnMap{
    top: 60px;
    bottom: 0;
    left: 120px;
    right: 0;;
  }

  .evButtononMap{
    top: 60px;
    bottom: 0;
    left: 155px;
    right: 0;
  }

  .zoomOutButtononMap {
    top: unset;
    bottom: 205px;
    left: unset;
    right: 10px;
  }

  .threedButtonOnMap{
    top: unset;
    bottom: 170px;
    left: unset;
    right: 10px;
  }

}

@media only screen and (max-height: 680px) and (max-width: 1010px) {

  .sidebarOpen .zoomOutButtononMap {
    top: unset;
    bottom: 225px;
    left: unset;
    right: 10px;
  }

  .sidebarOpen .threedButtonOnMap {
    top: unset;
    bottom: 190px;
    left: unset;
    right: 10px;
  }

}

@media only screen and (max-width: 640px) {

  .splitLeft{
    width: 100%;
    overflow:hidden;
  }

  div#sidebarExpander {
    right: unset;
    left: calc(50% - 40px);
    top: unset !important;
    bottom: 0;
    width: 80px;
    height: 30px;
    border-radius: 20px 20px 0px 0px;
    border-left: 1.5px solid #fff;
    border-right: 1.5px solid #fff;
    border-top: 1.5px solid #fff;
    border-bottom: unset;
  }
  
  div#sidebarExpander:hover {
    background-color: #003E66;
    width: 80px;
  }

  div#sidebarExpander ion-icon {
    width: 100%;
    height: 28px;
    margin: auto;
    padding-left: 0px;
    transform: rotate(90deg);
  }

  .mapboxgl-ctrl-geocoder {
    height: 38px;
  }

  .mapboxgl-ctrl-geocoder--icon-search {
    top: 8px !important;
  }

  .mapboxgl-ctrl-geocoder--input {
    height: 38px !important;
  }

  .mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > *{
    top: 2px !important;
  }

}

@media only screen and (max-width: 820px) {

  .pageHeader ion-img {
    height: 35px;
    width: 35px;
    top: 10px;
    right: 10px;
  }

  .ios-pageHeader ion-img{
    height:30px;
    width: 35px;
    top: 2px;
    right: 10px;
  }

  .pageHeader ion-button span {
    display: none;
  }

  .ios-pageHeader ion-button span {
    display: none;
  }

  #returnToSsenButton {
    width: unset;
    --padding-start: 15px;
    --padding-end: 12px;
  }

}

 @media only screen and (max-width: 640px) {

  .mapboxgl-ctrl-top-left{
    width: 100%;
  }

  .mapboxgl-ctrl-geocoder {
    width: calc(100% - 36px) !important;
  }

  .mapKey {
    margin-top: 45px;
    right: 10px;
  }

  .mapKeyClosed{
    margin-top: 45px;
    right: 0;
  }

  ion-radio-group#mainMapType{
    margin-top: 45px;
    right: 10px;
  }


  .summaryButtonOnMap  {
    top: 60px;
    bottom: 0;
    left: 15px;
    right: 0;
  }

  .filterButtonOnMap {
    top: 60px;
    bottom: 0;
    left: 50px;
    right: 0;
  }

  .downloadButtonOnMap {
    top: 60px;
    bottom: 0;
    left: 85px;
    right: 0;
  }
  
  .acronymButtonOnMap{
    top: 60px;
    bottom: 0;
    left: 120px;
    right: 0;;
  }

  .evButtononMap{
    top: 60px;
    bottom: 0;
    left: 155px;
    right: 0;
  }

  .zoomOutButtononMap {
    top: unset;
    bottom: 225px;
    left: unset;
    right: 10px;
  }

  .threedButtonOnMap{
    top: unset;
    bottom: 190px;
    left: unset;
    right: 10px;
  }

  .notification{
    position:absolute;
    left: 385px;
    top: 85px !important; 
    right:unset;
    bottom:unset;
    font-size: 1em;
    font-family: SSENRegular;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 0px;
    border-radius: 14px;
  }
  
}

@media only screen and (max-width: 1010px) and (min-height: 650px) {

  .sidebarOpen .summaryButtonOnMap  {
    top: unset;
    bottom: 420px;
    left: unset;
    right: 10px;
  }

  .sidebarOpen .filterButtonOnMap {
    top: unset;
    bottom: 385px;
    left: unset;
    right: 10px;
  }

  .sidebarOpen .downloadButtonOnMap {
    top: unset;
    bottom: 350px;
    left: unset;
    right: 10px;
  }

  .sidebarOpen .acronymButtonOnMap {
    top: unset;
    bottom: 305px;
    left: unset;
    right: 10px;
  }

  .sidebarOpen .evButtononMap {
    top: unset;
    bottom: 270px;
    left: unset;
    right: 10px;
  }

  .sidebarOpen .zoomOutButtononMap {
    top: unset;
    bottom: 225px;
    left: unset;
    right: 10px;
  }

  .sidebarOpen .threedButtonOnMap {
    top: unset;
    bottom: 190px;
    left: unset;
    right: 10px;
  }

}

@media only screen and (max-width: 725px) and (min-width: 640px) {

  .sidebarOpen .notification{
    top: 45px;
    left: 300px;
  }

  .sidebarOpen .notification .toast{
    min-width: 270px !important;
  }

}

@media only screen and (max-width: 850px) {

  .sidebarOpen .mapKey{
    display: none;
    margin-top: 60px;
    left: 15px;
    right: unset;
    transition: none;
  }

  .sidebarOpen .mapKeyClosed{
    display: none;
    margin-top: 55px;
    left: 15px;
    right: unset;
    transition: none;
  }

  .sidebarOpen ion-radio-group#mainMapType{
    display: none;
    margin-top: 60px;
    left: 15px;
    right: unset;
    transition: none;
  }
  
}

@media only screen and (max-width: 390px) {

  /* .pageHeader ion-img {
    left: -50px;
  }

  .ios-pageHeader ion-img{
    left: -50px;
  } */

  .notification{
    position:absolute;
    left: unset;
    right:-10px;
    bottom:unset;
    font-size: 1em;
    font-family: SSENRegular;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 0px;
    border-radius: 14px;
  }

  .notification .toast{
    min-width: 300px !important;
  }

  /* .generalInformationOnMap{
    top: 60px;
    bottom: 0;
    left: unset;
    right: 190px;
  } */

  /* .summaryButtonOnMap  {
    top: 60px;
    bottom: 0;
    left: unset;
    right: 160px;
  }

  .downloadButtonOnMap {
    top: 60px;
    bottom: 0;
    left: unset;
    right: 125px;
  }

  .filterButtonOnMap {
    top: 60px;
    bottom: 0;
    left: unset;
    right: 90px;
  }

  .zoomOutButtononMap {
    top: 60px;
    bottom: 0;
    left: unset;
    right: 55px;
  }

 .acronymButtonOnMap{
    top: 60px;
    bottom: 0;
    left: unset;
    right: 195px;
  }

  .evButtononMap{
    top: 60px;
    bottom: 0;
    left: unset;
    right: 230px;
  }

  .threedButtonOnMap{
    top: unset;
    bottom: 190px;
    left: unset;
    right: 10px;
  } */

  .pageHeader ion-button span {
    display: none;
  }
  
  .pageHeader ion-button ion-icon {
    padding: 0;
  }
  
  .ios-pageHeader ion-button ion-icon {
    padding: 0;
  }

  ion-row#timeline {
    zoom: 0.9;
  }

}


@media only screen and (max-height: 430px) and (max-width: 641px) {

  .mapboxgl-ctrl-top-left {
    width: calc(100% - 30px);
  }

  ion-radio-group#mainMapType {
    right: 50px;
  }

  .mapKey {
    right: 50px;
}

  .mapKeyClosed {
    right: 40px;
}

}

@media only screen and (max-height: 902px) {

  div#sidebarExpander {
    top: 29%;
  }

}

@media only screen and (max-height: 800px) {

  div#sidebarExpander {
    top: 16%;
  }

}

@media only screen and (max-height: 800px) and (min-width: 640px) {

  .mapKey{
    right: 50px;
  }

  .mapKeyClosed {
    right: 40px;
  }

}