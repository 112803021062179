/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /* --ion-color-primary: #5B8E9F;
  --ion-color-primary-rgb: 122,194,63;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #6bab37;
  --ion-color-primary-tint: #87c852; */

  /* --ion-color-primary: #629C49;
  --ion-color-primary-rgb: 98,156,73;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #6bab37;
  --ion-color-primary-tint: #87c852; */

  --ion-color-primary: #5B8E9F;
  --ion-color-primary-rgb: 91,142,159;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #6bab37;
  --ion-color-primary-tint: #629C49;

  /* --ion-color-primary: #5B8E9F;
  --ion-color-primary-rgb: 91,142,159;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #5B8E9F;
  --ion-color-primary-tint: #5B8E9F; */

  /* --ion-color-secondary: #003E66;
  --ion-color-secondary-rgb: 0,70,135;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #003e77;
  --ion-color-secondary-tint: #1a5993; */


  --ion-color-secondary: #003E66;
  --ion-color-secondary-rgb: 0,62,102;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #003e77;
  --ion-color-secondary-tint: #1a5993;

  --ion-color-tertiary: #ffffff;
  --ion-color-tertiary-rgb: 106,100,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-ssenyellow: #EBAC00;
  --ion-color-ssenyellow-rgb: 235,172,0;
  --ion-color-ssenyellow-contrast: #ffffff;
  --ion-color-ssenyellow-contrast-rgb: 255,255,255;
  --ion-color-ssenyellow-shade: #EBAC00;
  --ion-color-ssenyellow-tint: #EBAC00;

  --ion-color-ssengreen: #629C49;
  --ion-color-ssengreen-rgb: 98, 156, 73;
  --ion-color-ssengreen-contrast: #ffffff;
  --ion-color-ssengreen-contrast-rgb: 255, 255, 255;
  --ion-color-ssengreen-shade: #629C49;
  --ion-color-ssengreen-tint: #629C49;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,36,40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255,255,255;
  
}

/* @font-face {   
  font-family: 'SSENRegular';
  src: url("../fonts/PTSans-Regular.ttf")
}
@font-face {   
  font-family: 'SSENBold';
  src: url("../fonts/PTSans-Bold.ttf")
} */


@font-face {   
  font-family: 'SSENRegular';
  src: url("../fonts/MuseoSans_300.otf");
}
@font-face {   
  font-family: 'SSENBold';
  src: url("../fonts/MuseoSans_700.otf");
}