
.filterh5{
  margin-left: 10px;
  margin-top: 10px;
  position: absolute;
  font-family: SSENBold;
  padding-left: 10px;
}

body {
  padding:
    env(safe-area-inset-top, 20px)
    env(safe-area-inset-right, 20px)
    env(safe-area-inset-bottom, 20px)
    env(safe-area-inset-left, 20px);
}

.filterbuttons{
  float: right;
  padding-top: 5px;
}

.filtercontent{
  background-color: var(--ion-color-secondary);
}

div#filterList{
  display: flex;
  flex-direction: column;
}

.filterIconWithButtons{
  background-color: var(--ion-color-primary);
  width: 50px;
  margin: auto;
  text-align: center;
  position: relative;
  margin-top: 0px;
  padding-top: 0px;
}

.ios-filterIconWithButtons{
  background-color: var(--ion-color-primary);
  width: 50px;
  margin: auto;
  text-align: center;
  position: relative;
  margin-top: -20px;
  padding-top: 0px;
}
  
ion-icon#filterheader{
  width: 40px;
  height: 40px;
}
  
.filtertitle{
  text-align: center;
  font-family: SSENBold;
  font-size: 1.2em;
  font-weight: bold;
}
div#dataFilterItem{
  display: flex;
  align-items: center;
  background-color: #ffffff10;
  margin: 3px 15px 3px 15px;
  padding: 8px;
  border-radius: 25px;
}

div#dataFilterItem:first-child{
  margin-top: 10px;
}

div#dataFilterItem ion-icon{
  height: 32px;
  width: 25px;
  padding-left: 10px;
  padding-top: 0;
}

div#dataFilterItem ion-label{
  height: auto;
  text-align: left;
  margin: 0;
  padding-bottom: 0;
  padding-top: 0;
  font-family: SSENRegular;
  flex-grow: 1;
}

ion-toggle{
  --background: var(--ion-color-primary);
  --handle-background:  #BFC3CC;

  --background-checked: #629C49;
  --handle-background-checked: #fff;
}