.header {
	background-color: var(--ion-color-primary);
	height: 180px;
	padding: env(safe-area-inset-top, 20px) env(safe-area-inset-right, 0px) env(safe-area-inset-left, 0px) env(safe-area-inset-bottom, 5px);
}

.content {
	background-color: var(--ion-color-secondary);
	height: calc(100% - 250px);
	display: flex;
	flex-direction: column;
	padding: env(safe-area-inset-bottom, 20px);
}

.contentScroll {
	background-color: var(--ion-color-secondary);
	overflow-y: auto;
	overflow-x: hidden;
	padding-bottom: 20px;
	height: calc(100% - 250px);
}

.iconWithButtons {
	background-color: var(--ion-color-primary);
	width: 50px;
	margin: auto;
	text-align: center;
	position: relative;
	margin-top: -20px;
	padding-top: 0px;
	height: 50px;
}

ion-label a {
	color: white;
	text-decoration: underline;
}

.siteClassification {
	width: 100%;
}

ion-icon#iconClassification {
	height: 15px;
	width: 15px;
	float: left;
	margin-top: 4px;
}

ion-label#labelClassification {
	margin-top: -7px;
	margin-bottom: -8px;
	padding-left: 6px;
}

.detailsTitle {
	margin-top: 5px;
	text-align: center;
	font-family: SSENBold;
	font-size: 1em;
	font-weight: bold;
}

.ios-iconWithButtons {
	background-color: var(--ion-color-primary);
	width: 50px;
	margin: auto;
	text-align: center;
	position: relative;
	margin-top: -20px;
	padding-top: 0px;
}

.icon {
	background-color: var(--ion-color-primary);
	width: 50px;
	margin: auto;
	text-align: center;
	position: relative;
	padding-top: 10px;
	font-family: SSENRegular;
}

.title {
	margin-top: 0px;
	text-align: center;
	font-family: SSENBold;
	font-size: 1.1em;
	font-weight: bold;
}

.titleAcronymWithButtons {
	background-color: var(--ion-color-primary);
	width: 50px;
	margin: auto;
	text-align: center;
	position: relative;
	margin-top: 0px;
	padding-top: 0px;
	height: 60px;
}

.titleAcronym {
	margin-top: 0px;
	text-align: center;
	font-family: SSENBold;
	font-size: 1.2em;
	font-weight: 700;
}

ion-label#header {
	font-size: 4em;
	padding-right: 0;
	padding-left: 0;
	padding-top: 0;
	padding-bottom: 0;
}

ion-icon#header {
	width: 50px;
	height: 50px;
}

.subtitle {
	margin-top: 0px;
	text-align: center;
	font-family: SSENBold;
	font-size: 0.8em;
	padding-top: 20px;
}

ion-icon#backArrow {
	font-size: 10px;
}

ion-label#segmented {
	font-size: 12px;
	padding-top: 8.5px;
	padding-left: 5px;
}

ion-segment {
	background-color: white;
	border-radius: 15px;
	height: 30px !important;
	margin: 8px 5px 5px 5px;
	width: auto;
	font-family: SSENRegular;
}

ion-segment-button {
	background-color: white;
	color: var(--ion-color-primary);
	height: 30px !important;
	border-radius: 8pt;
	min-height: 10px;
	padding-right: 8px;
	--indicator-color: none !important;
}

.segment-button-checked {
	background-color: var(--ion-color-primary);
	color: white;
	border-bottom-width: 0px;
	border-radius: 15px;
	--indicator-color: transparent !important;
}

.segment-button-checked:hover {
	opacity: 0.9;
}

.segment-button-checked>ion-label {
	color: white;
}

ion-segment-button>ion-label {
	color: var(--ion-color-primary);
}

.sidebarButtonFixedWidth {
	width: 240px;
}

.affectedByOutage {
	border-top: 2px solid #336B9F;
	font-family: SSENRegular;
	margin-left: 10px;
	margin-right: 10px;
	text-align: center;
	font-size: .05em;
	padding-bottom: 0px;
	padding-top: 10px;
}

.contactUs {
	margin-top: 0px;
	text-align: center;
	font-family: SSENBold;
	font-size: .05em;
	padding-top: 10px;
}

.titleSmallerFont {
	margin-top: 0px;
	text-align: center;
	font-family: SSENBold;
	font-size: .05em;
}

.titleWeighted {
	margin-top: 10px;
	text-align: center;
	font-weight: 300;
	font-family: SSENBold;
	font-size: 1.1em;
}

ion-title {
	padding-bottom: 5px;
	font-size: 16px;
	font-weight: normal;
	font-family: SSENBold;
}

ion-popover {
	margin-left: 10px;
	position: absolute;
	margin-top: 10px;
	color: var(--ion-color-secondary);
	font-family: SSENRegular;
}

.h5 {
	margin-left: 10px;
	position: absolute;
	margin-top: 9px !important;
	font-family: SSENBold;
}

#gspTitle {
	font-size: 12pt;
}

.evbuttons {
	position: relative;
	justify-content: right;
	float: right;
	right: 0;
	padding: 1px 5px;
}

.filterSortbuttons {
    position: relative;
    justify-content: right;
    right: 0;
    float: right;
    padding: 1px 5px;
    white-space: nowrap;
}

.buttons {
	display: flex;
	justify-content: right;
	padding: 1px 5px;
	width: 100%;
}

.generationListHeader .buttons ion-button {
	width: 100px;
}

div.generationListHeader {
	/* background-color: #6B7C9D; */
	/* background-color: #27486F; */
	background-color: var(--ion-color-secondary);
	/*#253746; #476084;*/
	height: 100px;
	flex-basis: auto;
}

div.correspondingGSP a {
	transition: 0.1s;
	color: white;
	text-decoration: underline !important;
	cursor: pointer;
}

.centreButtons {
	padding-top: 10px;
}

.splitRight {
	position: absolute;
	height: 100%;
	width: 370px;
	top: 70px;
	right: 0;
	background-color: var(--ion-color-secondary);
}

div.listHeader {
	/* background-color: #6B7C9D; */
	/* background-color: #27486F; */
	background-color: var(--ion-color-secondary);
	/*#253746; #476084;*/
	height: 42px;
	flex-basis: auto;
}

.startButton {
	position: absolute;
	margin: auto;
	margin-left: -150px;
	left: 50%;
	bottom: 22px;
}

.startButton.startButtonSmall {
	display: none;
}

.startButton.startButtonLarge {
	display: block;
}

.messagePadding {
	padding-right: 10px;
	padding-left: 10px;
	text-align: justify;
	padding-bottom: 10px;
	margin-top: -22px;
	font-weight: 300;
	font-family: SSENRegular;
}

ion-button {
	font-family: SSENRegular;
}

ion-button#back {
	padding-top: 5px;
	width: 110px;
	height: 35px;
	padding-left: 5px;
	font-family: SSENRegular;
}

ion-button#backHidden {
	position: absolute;
	left: 0;
	padding-top: 5px;
	width: 110px;
	height: 35px;
	padding-left: 5px;
	font-family: SSENRegular;
	visibility: hidden;
}

ion-button#close {
	position: absolute;
	right: 0;
	width: 110px;
	height: 35px;
	float: right;
	padding-top: 5px;
	padding-right: 5px;
	font-family: SSENRegular;
}

ion-button#zoomButton {
	height: 30px;
	width: 40px;
}

/* Licences modal styles */

ion-modal#licencesModal {
	--background: var(--ion-color-secondary);
	color: white;
	text-align: center;
	--backdrop-opacity: 0.8 !important;
	--border-radius: 10px;
	--max-height: 40em !important;
	--width: 40em;
	padding: 10px;
	position: absolute;
	font-family: SSENRegular;
}


.modalTitle {
	margin-bottom: 0;
}

#versionNo {
	margin-bottom: 5px;
	margin-top: 1px;
	font-size: 0.9em;
}

.modalCloseButton {
	position: absolute;
	right: 10px;
	top: 15px;
	font-size: 25px;
	cursor: pointer;
	pointer-events: all;
}

@media only screen and (max-width: 390px) {
	.modalTitle {
		margin-right: 32px;
	}

	#versionNo {
		margin-right: 32px;
	}
}

.downloadList {
	padding: 0;
	background-color: var(--ion-color-secondary);
}

.downloadList ion-item {
	--background: #fff1;
	border: none;
	border-top: 1px #ffffff30 solid;
	padding: 0;
	cursor: pointer
}

.downloadList ion-item:last-child {
	border-bottom: 1px #ffffff30 solid;
}

.downloadList ion-item:hover {
	--background: #fff2;
}

.downloadList ion-item ion-icon {
	margin-left: 0;
	margin-right: 0;
}

.downloadList ion-item ion-label {
	height: auto;
}

.downloadList ion-item ion-label p {
	color: #c9c9c9;
	font-weight: bold;
}

ion-grid.dataList {
	padding: 0;
}

ion-grid.dataList {
	padding: 0;
}

ion-grid.dataList>ion-row {
	background-color: #ffffff10;
	padding: 0 10px;
	border-top: 1px #ffffff30 solid;
	cursor: pointer;
	align-items: center;
	transition: 0.1s;
}

ion-grid.dataList>ion-row:last-child {
	border-bottom: 1px #ffffff30 solid;
}

ion-grid.dataList>ion-row:hover {
	background-color: #ffffff20;
	padding: 5px 10px;
}

ion-grid#detailsheaderGrid {
	padding-bottom: 0px;
	margin-top: -10px;
}

ion-grid#detailsheaderGrid>ion-row {
	gap: 15px;
}

ion-col.detailsHeaderLeftColext {
	text-align: start;
	font-size: 0.7em;
	font-family: SSENBold;
	margin: 0;
	padding: 2px;
	margin-left: 5px;
	flex: 0;
	white-space: nowrap;
}

ion-col.detailsHeaderRightColText {
	text-align: end;
	font-size: 0.7em;
	font-family: SSENBold;
	margin: 0;
	padding: 2px;
	margin-right: 5px;
	white-space: nowrap;
	flex: 0;
}

.detailsIconWithButtons {
	background-color: var(--ion-color-primary);
	width: 35px;
	margin: auto;
	text-align: center;
	position: relative;
	margin-top: -35px;
	padding-top: 0px;
	height: 35px;
}

ion-icon#detailsheader {
	width: 35px;
	height: 35px;
}

ion-col.dataListItemText {
	text-align: start;
	margin-left: 10px;
	font-size: 0.85em;
	font-family: SSENRegular;
	padding: 10px
}

ion-col.dataListItemText>div {
	padding-bottom: 2px;
	padding-top: 2px;
}

ion-col.dataListItemText>div.multiLine {
	display: flex;
	flex-direction: column;
}

ion-col.dataListItemText b {
	font-family: "SSENBold";
	letter-spacing: 0px;
}

ion-col.dataListItemText b#GSPName {
	font-family: "SSENBold";
	letter-spacing: 0px;
	font-size: 1.2em;
}

ion-grid#headerGrid {
	padding-bottom: 20px;
}


/* ion-grid{
    margin-bottom: 10px;
  } */

ion-header {
	background-color: var(--ion-color-primary);
	font-family: SSENBold;
}

ion-card ion-card-title {
	text-align: center;
	color: white;
	font-size: 14px;
	font-weight: normal;
	font-family: SSENBold;
}

ion-card ion-card-content {
	text-align: justify;
	color: white;
	font-size: 0.8em;
	font-weight: 300;
	font-family: SSENRegular;
}

ion-card {
	color: white;
	font-size: 10px;
	background-color: var(--ion-color-secondary);
	font-family: SSENRegular;
}

ion-content {
	--padding-start: 8px;
	--padding-end: 8px;
	--padding-top: 20px;
	--padding-bottom: 20px;
}

ion-content#gridList {
	background-color: var(--ion-color-secondary);
}

ion-note {
	margin-bottom: 30px;
	font-family: SSENRegular;
}

iion-note#contact {
	margin-bottom: 30px;
	font-weight: 300;
	font-family: SSENRegular;
}

ion-list-header,
ion-note {
	padding-left: 10px;
	color: #ffffff;
	text-align: center;
	font-family: SSENRegular;
}

ion-list>ion-item {
	border-bottom: #003659 1.2px solid;
	--border-style: none;
	padding-top: 5px;
	padding-bottom: 5px;
}

ion-list#overlay-list {
	/* Adds line */
	border-bottom: 1px solid #ffffff;
}

ion-list#info {
	background-color: #003E66;
	overflow-y: auto;
	font-family: SSENRegular;
	flex-basis: 100%;
	text-align: center;
	padding-top: 3px;
}

on-list#overlay-list ion-list-header {
	font-size: 22px;
	font-weight: 600;
	min-height: 20px;
	font-family: SSENRegular;
}

ion-col {
	text-align: center;
	font-family: SSENRegular;
}

ion-col#left {
	text-align: left;
	font-size: 0.8em;
	font-family: SSENRegular;
}

ion-col#title {
	text-align: left;
	font-size: 0.8em;
	font-family: SSENBold;
}

ion-col#data {
	text-align: center;
	font-size: 1em;
	font-family: SSENRegular;
}

ion-col#line {
	border-right: 1px solid white;
	font-family: SSENRegular;
	font-size: 1.1em;
}

ion-col#noLine {
	font-family: SSENRegular;
	font-size: 1.1em;
}

ion-col#typeWithline {
	border-right: 1px solid white;
	font-family: SSENRegular;
	font-size: 0.8em;
}

ion-col#typeNoline {
	font-family: SSENRegular;
	font-size: 0.8em;
}

ion-col#titleCol {
	font-size: 0.9em;
	font-weight: 300;
	font-family: SSENBold;
}

ion-col#subtitle {
	font-size: 0.8em;
	font-family: SSENBold;
}

ion-col#subtitleLine,
ion-row#subtitleLine {
	font-size: 0.8em;
	font-family: SSENBold;
}

ion-col#lightLine {
	border-right: 1px solid #336B9F;
	font-family: SSENRegular;
}

ion-col#titleColWithLine {
	font-size: 0.9em;
	font-weight: 300;
	border-right: 1px solid white;
	font-family: SSENBold;
}

ion-row#bottomBorder {
	border-bottom: 2px solid #336B9F;
	font-family: SSENRegular;
	margin-left: 10px;
	margin-right: 10px;
	padding-bottom: 10px;
}

ion-row#topBorder {
	border-top: 2px solid #336B9F;
	font-family: SSENRegular;
	margin-left: 10px;
	margin-right: 10px;
	padding-top: 10px;
}

ion-icon#colourIcon {
	height: 35px;
	width: 35px;
	color: var(--ion-color-primary);
	margin-bottom: 3px;
	margin-top: 3px;
}

ion-icon#GSPIcon {
	font-size: 50px;
	margin-top: -5px;
	font-family: SSENRegular;
	width: 38px;
	height: 45px;
}

ion-toggle {
	color: var(--ion-color-primary);
}

ion-label {
	height: 38px;
	text-align: left;
	padding-top: 10px;
	padding-right: 0px;
	padding-bottom: 10px;
	padding-left: 15px;
	margin: 0;
	--ion-font-family: SSENRegular;
	font-size: 1em;
}

ion-item#removeLine {
	--border-color: var(--ion-color-secondary);
	--ion-item-background: var(--ion-color-secondary);
	font-size: 14px;
}

ion-grid#postCodeAreas {
	padding-left: 0;
	padding-right: 0;
}


/* Postcode Modal Styles */

ion-modal#post-code-areas {
	--background: var(--ion-color-secondary);
	text-align: center;
	--backdrop-opacity: 0.8 !important;
	--border-radius: 10px;
	--max-height: 30em !important;
	--width: 30em !important;
	padding: 10px;
	position: absolute;
}

ion-modal#post-code-areas ion-button {
	width: 15em;
	height: 3em;
	margin: 1em auto;
	flex: none;
}


/* EV selection Modal Styles */

ion-modal#multiple-ev-selected {
	--background: var(--ion-color-secondary);
	color: white;
	text-align: center;
	--backdrop-opacity: 0.8 !important;
	--border-radius: 10px;
	--max-height: 30em !important;
	--width: 30em !important;
	padding: 10px;
	position: absolute;
}

ion-modal#multiple-ev-selected h3 {
	margin-left: 10px;
	margin-right: 10px;
}

ion-modal#multiple-ev-selected h6 {
	margin-top: 0;
	margin-bottom: 15px;
	margin-left: 10px;
	margin-right: 10px;
}

ion-modal#multiple-ev-selected ion-list {
	background: none;
	overflow-y: auto;
	flex-grow: 1;
}

ion-modal#multiple-ev-selected ion-list ion-item {
	--background: #ffffff10;
	cursor: pointer;
	border-top: 1px #ffffff30 solid;
}

ion-modal#multiple-ev-selected ion-list ion-item:last-child {
	border-bottom: 1px #ffffff30 solid;
}

ion-modal#multiple-ev-selected ion-list ion-item:hover {
	--background: #ffffff20;
}

ion-modal#multiple-ev-selected ion-list ion-item ion-label:first-of-type {
	padding-bottom: 0;
	font-weight: bold;
}

ion-modal#multiple-ev-selected ion-list ion-item ion-label:last-of-type {
	height: 30px;
	padding-top: 0;
	margin-top: -5px;
	font-size: 0.9em;
}

.multipleEvChargerThumb {
	height: 32px;
	width: 30px;
}

ion-modal#multiple-ev-selected ion-button {
	width: 15em;
	height: 3em;
	margin: 1em auto;
	flex: none;
}


/* Invalid Mobile Modal Styles */

ion-modal#invalid-mobile {
	--background: var(--ion-color-secondary);
	text-align: center;
	--backdrop-opacity: 0.8 !important;
	--border-radius: 10px;
	--height: 13em !important;
	--width: 20em !important;
	position: absolute;
}

ion-modal#invalid-mobile .ion-page {
	margin: 10px;
}

ion-modal#invalid-mobile ion-button {
	width: 15em;
	margin: 1em auto;
}

ion-modal#invalid-mobile h6 {
	text-align: center;
	padding-left: 1em;
	color: white;
}

ion-modal#invalid-mobile h3 {
	color: white;
}

ion-row#postcodes {
	padding-bottom: 0.5em;
	font-size: 0.9em;
	;
}

ion-item.shadedItem {
	/* --ion-item-background: #445263; */
	--ion-item-background: #ffffff10;
	--border-radius: 25px;
	--border-style: none;
	margin: 0 10px 5px 10px;
	font-size: 1em;
}


/* EV List Styles */

ion-list.evList {
	height: calc(100% - 293px);
	flex-basis: 100%;
	overflow-y: auto;
	padding: 0;
	/* padding-bottom: 10px; */
}

ion-list.evList ion-item {
	--background: #ffffff10;
	border: none;
	border-top: 1px #ffffff30 solid;
	padding: 0;
}

ion-list.evList ion-item:last-child {
	border-bottom: 1px #ffffff30 solid;
}

ion-list.evList ion-item:hover {
	--background: #ffffff20;
}

ion-list.evList ion-item ion-grid ion-row:first-child {
	cursor: pointer;
}

ion-list.evList ion-item.selected #chargerName,
ion-list.evList ion-item.selected #chargerNetwork {
	white-space: normal;
	text-overflow: unset;
}

/* ion-list.evList,ion-list.evList *{
	background: var(--ion-color-secondary);
  } */

ion-list.evList .loadingContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 50px;
	animation: fadeIn;
	animation-duration: 2s;
}

ion-list.evList .loadingContainer ion-icon {
	font-size: 4em;
}

ion-list.evList .loadingContainer ion-icon#loading {
	font-size: 6em;
}

ion-list.evList .loadingContainer ion-label:first-of-type {
	margin-top: 1em;
	font-size: 1.2em;
	font-family: SSENBold;
}

ion-list.evList .loadingContainer ion-label {
	font-size: 1em;
}

ion-list.evList .contained {
	width: 100%;
	padding: 0.6em;
	margin-top: 2.5px;
	margin-bottom: 2.5px;
	border-radius: 12px;
	background-color: #ffffff22 !important;
}

ion-list.evList .contained * {
	background-color: #ffffff00;
}

/* ion-list.evList ion-item.pulse{
	animation: pulse;
	animation-duration: 0.8s;
  } */

ion-list.evList ion-label {
	height: auto;
	padding: 0;
	font-family: SSENRegular;
	text-align: left;
}

ion-list.evList ion-grid {
	padding: 0;
	margin: 0;
	padding-bottom: 3px;
	padding-top: 3px;
}

ion-list.evList ion-row {
	margin-top: 5px;
	margin-bottom: 5px;
}

ion-list.evList ion-col {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 0;
}

ion-list.evList ion-row:first-child ion-col {
	align-items: center;
}

ion-list.evList #availabilityCol ion-icon {
	text-align: center;
	font-size: 1.7em;
	font-family: SSENBold;
	margin-bottom: 5px;
}

ion-list.evList #availabilityCol ion-label {
	text-align: center;
	font-size: 0.7em;
	font-family: SSENBold;
}

ion-list.evList #chargerDetailsCol ion-label {
	align-self: flex-start;
	text-align: left;
	margin-left: 10px;
	margin-right: 10px;
	font-size: 0.9em;
	font-family: SSENBold;
	width: 190px;
}

ion-list.evList #chargerDetailsCol ion-label:last-child {
	font-size: 0.8em;
	font-family: SSENRegular;
}

ion-list.evList #distanceCol ion-label {
	text-align: center;
	font-size: 0.7em;
	font-family: SSENRegular;
}

ion-list.evList #distanceCol ion-label:last-child {
	font-family: SSENBold;
}

ion-list.evList ion-row.expandedSection.collapsed {
	display: none;
}

ion-list.evList ion-label.title {
	font-size: 1em;
	font-family: SSENBold;
	text-decoration: underline;
	text-align: left;
	margin-top: 0;
}

ion-list.evList ion-label.large {
	font-size: 0.9em;
	font-family: SSENBold;
	margin-left: 8px;
	margin-top: 5px;
}

ion-list.evList ion-label.medium {
	font-size: 0.9em;
	font-family: SSENRegular;
	margin-left: 12px;
}

ion-list.evList ion-label.small {
	font-size: 0.8em;
	font-family: SSENRegular;
	margin-left: 14px;
}

ion-list.evList a {
	color: white;
}



ion-list.evList .connector {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-top: 6px;
}

ion-list.evList .connector ion-icon {
	font-size: 1.5em;
	margin-right: 5px;
	margin-left: 8px;
}

.evChargerButton {
	height: 30px;
}


/* Acronym Style */

ion-list.acronymList {
	padding-top: 0;
	height: 100% !important;
}

ion-list.acronymList,
ion-list.acronymList * {
	background: var(--ion-color-secondary);
}

ion-list.acronymList ion-item-divider,
ion-list.acronymList ion-item-divider * {
	background: var(--ion-color-secondary);
	color: #ffffff;
	cursor: pointer;
}

ion-list.acronymList ion-item-divider:hover,
ion-list.acronymList ion-item-divider:hover * {
	background: var(--ion-color-secondary);
}

ion-list.acronymList ion-range ion-label:first-child {
	padding-right: 15px;
}

div#acronymContent {
	background-color: var(--ion-color-secondary);
}

div#acronymContent .multiLine {
	height: auto;
	white-space: normal;
	font-size: 11pt;
	padding-top: 10px;
	line-height: 1.5em;
}

ion-label#acronymItem {
	font-size: 12pt;
}

div#acronymContent .multiLine ul {
	padding-top: -5px;
}


/* Detailed View Style */

ion-list.gspDetailsList {
	padding-top: 0;
	height: 100% !important;
}


/* ion-list.gspDetailsList,ion-list.gspDetailsList *{
    background: var(--ion-color-secondary);
  } */

ion-list.gspDetailsList ion-item-divider,
ion-list.gspDetailsList ion-item-divider * {
	background: var(--ion-color-secondary);
	color: #ffffff;
	cursor: pointer;
	position: sticky;
	top: 0;
}

ion-list.gspDetailsList ion-item-divider:hover,
ion-list.gspDetailsList ion-item-divider:hover * {
	background: var(--ion-color-secondary);
}

ion-list.gspDetailsList ion-range ion-label:first-child {
	padding-right: 15px;
}

div#gspDetailsContent {
	background-color: var(--ion-color-secondary);
}

div#gspDetailsContent .multiLine {
	height: auto;
	white-space: normal;
	font-size: 11pt;
	padding-top: 10px;
	line-height: 1.5em;
}

ion-label#gspDetailsItem {
	font-size: 12pt;
}

div#gspDetailsContent .multiLine ul {
	padding-top: -5px;
}

ion-col.gspDetailsItemText {
	text-align: start;
	margin-left: 10px;
	font-size: 0.85em;
	font-family: SSENRegular;
	padding: 0;
	margin: 0;
	margin-top: 3px;
}

ion-col.gspDetailsItemText>div {
	padding-bottom: 5px;
	padding-top: 5px;
	display: flex;
	flex-direction: column;
}

ion-col.gspDetailsItemText>div:first-of-type {
	padding-top: 8px;
}

ion-col.gspDetailsItemText>div:last-of-type {
	padding-bottom: 10px;
}

ion-col.gspDetailsItemText b {
	font-family: "SSENBold";
	letter-spacing: 0px;
	padding-bottom: 1px;
}

ion-grid.gspDetailsGrid {
	margin: 0;
}

div.listOfGens:first-of-type {
	border-top: 2px #ffffff30 solid
}

div.listOfGens {
	border-bottom: 2px #ffffff30 solid;
}

div.listOfGens>div {
	display: flex;
	flex-direction: row;
	gap: 18px;
}

div.textSeperator {
	flex-grow: 1;
	height: 1.2px;
	background-color: rgba(255, 255, 255, 0);
	opacity: 0.25;
	background-image: linear-gradient(to right, #ffffff, #ffffff 4px, rgba(255, 255, 255, 0) 4px, rgba(255, 255, 255, 0));
	background-size: 6px 100%;
	align-self: center;
}

div.textSeperator.highOpacity {
	opacity: 0.6;
}

div.listOfGens>div>span {
	text-align: end;
}


/* EV Filtering List Styles */

ion-list.evFilteringList {
	padding-top: 0;
	height: 100% !important;
}

ion-list.evFilteringList,
ion-list.evFilteringList * {
	background: #ffffff00;
	/*var(--ion-color-secondary);*/
}

ion-list.evFilteringList ion-item-divider,
ion-list.evFilteringList ion-item-divider * {
	background: #ffffff00;
	color: #ffffff;
	cursor: pointer;
}

ion-list.evFilteringList ion-item-divider:hover,
ion-list.evFilteringList ion-item-divider:hover * {
	background: #3b6a8a;
}

ion-list.evFilteringList ion-item {
	font-size: 0.95em;
	border: none;
	padding: 0;
}

ion-list.evFilteringList ion-range ion-label:first-child {
	padding-right: 15px;
}

/* ion-list.evFilteringList ion-item-divider  ion-label, ion-list.evFilteringList ion-item-divider  ion-label*{
	background-color: #ffffff10; 
  }
  
  ion-list.evFilteringList ion-item-divider ion-icon{
	background-color: #ffffff10;
  } */

div#evFilterContent {
	background-color: #ffffff10;
}

.multipleEvAlert {
	--background: var(--ion-color-secondary);
	--max-width: 500px;
	--width: 500px;
	padding-left: 10px;
	padding-right: 10px;
}

.alert-radio-label {
	color: white !important;
}

.alert-wrapper {
	border-radius: 11px !important;
	border-style: solid !important;
	border-color: var(--ion-color-primary) !important;
	border-width: 2px !important;
}

#applyFilters {
	margin: 10px 10px 10px 10px;
}

.evFooter {
	position: relative;
	bottom: 0;
	padding: 3px 10px 3px 10px;
	background-color: var(--ion-color-secondary);
	text-align: center;
	flex-basis: auto;
}

.evFooter a {
	transition: 0.1s;
}

.evFooter a:hover {
	color: white;
}

.policyFooter {
	position: relative;
	bottom: 0;
	padding: 3px 10px 3px 10px;
	background-color: var(--ion-color-secondary);
	text-align: center;
	flex-basis: auto;
	font-family: SSENBold;
}

.policyFooter a,
.policyFooter button {
	color: white;
	transition: 0.1s;
}

.policyFooter a:hover,
.policyFooter button:hover {
	color: var(--ion-color-ssengreen);
}

.linkButton {
	color: var(--ion-color-primary, #3880ff);
	background: none;
	text-decoration: underline;
	padding: 0;
	margin: 0;
	font-size: 12px;
	font-family: SSENBold;
}

/* EV selection Modal Styles */

ion-modal#multiple-ev-selected {
	--background: var(--ion-color-secondary);
	color: white;
	text-align: center;
	--backdrop-opacity: 0.8 !important;
	--border-radius: 10px;
	--max-height: 30em !important;
	--width: 30em !important;
	padding: 10px;
	position: absolute;
}

ion-modal#multiple-ev-selected h3 {
	margin-left: 10px;
	margin-right: 10px;
	font-family: 'SSENRegular';
}

ion-modal#multiple-ev-selected h6 {
	margin-top: 0;
	margin-bottom: 15px;
	margin-left: 10px;
	margin-right: 10px;
	font-family: 'SSENRegular';
}

ion-modal#multiple-ev-selected ion-list {
	background: none;
	overflow-y: auto;
	flex-grow: 1;
}

ion-modal#multiple-ev-selected ion-list ion-item {
	--background: #ffffff10;
	cursor: pointer;
	border-top: 1px #ffffff30 solid;
	padding: 0;
	border-bottom: 0;
}

ion-modal#multiple-ev-selected ion-list ion-item:last-child {
	border-bottom: 1px #ffffff30 solid;
}

ion-modal#multiple-ev-selected ion-list ion-item:hover {
	--background: #ffffff20;
}

ion-modal#multiple-ev-selected ion-list ion-item ion-label:first-of-type {
	padding-bottom: 0;
	font-weight: bold;
}

ion-modal#multiple-ev-selected ion-list ion-item ion-label:last-of-type {
	height: 30px;
	padding-top: 0;
	margin-top: -5px;
	font-size: 0.9em;
}

.multipleEvChargerThumb {
	height: 32px;
	width: 30px;
}

ion-modal#multiple-ev-selected ion-button {
	width: 15em;
	height: 3em;
	margin: 1em auto;
	flex: none;
}


/* Animations */

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes pulse {
	0% {
		opacity: 100%;
	}

	25% {
		opacity: 80%;
	}

	50% {
		opacity: 100%;
	}

	75% {
		opacity: 80%;
	}

	100% {
		opacity: 100%;
	}
}


/* Slider Styles */

::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-track {
	/* box-shadow: inset 0 0 3px #ffffff7d; */
	/* background: #629C4950; */
	background: #5B8E9F50;
	border-radius: 0px;
}

::-webkit-scrollbar-thumb {
	/* background: #629C49c0; */
	background: #5B8E9Fc0;
	border-radius: 10px;
}

.notificationLabel {
	margin-top: -17px;
	margin-left: 10px;
}

ion-radio {
	--color: white;
	margin-top: -9px;
	width: 15px;
	height: 15px;
}

.radio-md .radio-icon,
.radio-wp .radio-icon {
	border-radius: unset !important;
}

ion-radio-group#notificationSelection {
	--ion-background-color: secondary;
	transition: 0.2s;
	height: 30px;
}

.notificationItem {
	--ion-background-color: secondary;
	height: 25px;
	margin-top: -4px;
	font-size: 12px;
	font-family: SSENRegular;
	width: 130px;
	padding-left: 15px;
}

ion-item#mobileInfo {
	--ion-item-background: secondary;
	--border-color: var(--ion-color-secondary);
	margin-top: 5px;
	display: flex;
}

ion-item#mobileInfo.hidden {
	display: none;
}

.mobile-sms {
	--ion-item-background: #ffffff20;
	--border-color: var(--ion-color-secondary);
	border-radius: 10px;
	display: flex;
	font-size: 10pt;
	font-family: SSENRegular;
	margin-top: 15px;
	margin-bottom: 5px;
	margin-left: 5px;
	margin-right: 5px;
	animation-name: fadeIn;
	animation-duration: 1s;
}

.mobile-sms ion-icon {
	color: var(--ion-color-primary);
}

.mobile-sms ion-label {
	height: auto;
}

ion-item#mobile-done {
	--ion-item-background: #ffffff20;
	--border-color: var(--ion-color-secondary);
	border-radius: 10px;
	display: flex;
	font-size: 10pt;
	font-family: SSENRegular;
	margin-top: 5px;
	margin-bottom: 5px;
	margin-left: 5px;
	margin-right: 5px;
	animation-name: fadeIn;
	animation-duration: 1s;
}

ion-item#mobile-done ion-icon {
	color: var(--ion-color-primary);
}

ion-item#mobile-done ion-label {
	height: auto;
}

ion-input#mobile-textbox {
	flex: 1;
	background-color: white;
	font-size: 10pt;
	font-family: SSENRegular;
	border-radius: 10px 10px 10px 10px;
	color: black;
	--padding-start: 4px;
	height: 30px;
}

ion-button#mobile-send {
	padding-left: 10px;
	width: 50px;
	height: 30px;
}


/* General Information Styles */

p#top {
	margin-top: 20px;
}

h2#subHeading {
	font-size: 1.2em;
	font-family: "SSENBold";
}

h3#subHeading {
	font-size: 1.1em;
	font-family: "SSENBold";
	text-align: left;
}

h2#subHeading.textCentered {
	text-align: center;
}

ol#preparing {
	margin-top: 0px;
}

h1#titleInfo {
	font-size: 1.5em;
	text-align: center;
	font-family: "SSENBold";
}

p#address {
	margin: 0;
	padding-left: 2em;
	font-family: "SSENRegular";
}

h2#list {
	font-size: 1.2em;
	margin-bottom: 5;
	margin-left: -40px;
	padding-left: 10px;
	font-family: "SSENBold";
}

p#list {
	margin-bottom: 5;
	margin-left: -40px;
	font-family: "SSENRegular";
}

h3#safety {
	font-size: 1.5em;
	text-align: center;
	font-family: "SSENBold";
}

.infoIconWithButtons {
	background-color: var(--ion-color-primary);
	width: 50px;
	margin: auto;
	text-align: center;
	position: relative;
	margin-top: 0px;
	padding-top: 0px;
}

.ios-infoIconWithButtons {
	background-color: var(--ion-color-primary);
	width: 50px;
	margin: auto;
	text-align: center;
	position: relative;
	margin-top: -20px;
	padding-top: 0px;
}

ion-icon#infoheader {
	width: 40px;
	height: 40px;
	margin-top: 10px;
}

.infotitle {
	margin-top: 5px;
	text-align: center;
	font-family: SSENBold;
	font-size: 1.2em;
}

.termsAndConditionsContent {
	font-family: "SSENRegular";
	padding-left: 15px;
	padding-right: 15px;
	font-size: 0.85em;
}

div#videoPlayer {
	width: 100%;
	margin: 0 auto;
	display: block;
	margin-top: 20px;
}

ion-button.video {
	font-family: "SSENRegular";
	width: 80%;
	margin: 0 auto;
	display: block;
	margin-bottom: 20px;
}

ion-grid.infoList {
	padding-top: 0;
}

ion-grid.infoList>ion-row {
	background-color: #ffffff10;
	padding: 0 10px;
	border-top: 1px #ffffff30 solid;
	cursor: pointer;
	align-items: center;
	transition: 0.1s;
	height: 50px;
}

ion-grid.infoList>ion-row:last-child {
	border-bottom: 1px #ffffff30 solid;
}

ion-grid.infoList>ion-row:hover {
	background-color: #ffffff20;
	padding: 5px 10px;
}

ion-col.infoListItemText {
	text-align: start;
	margin-left: 10px;
	font-size: 0.85em;
	font-family: SSENRegular;
	padding: 10px;
}

ion-col.infoListItemText>div {
	padding-bottom: 2px;
	padding-top: 2px;
}

ion-col.infoListItemText b {
	font-family: "SSENBold";
	letter-spacing: 0px;
}

.infoContent {
	margin-top: 100px;
}

ion-img#contactImage {
	width: 25px;
	height: 25px;
	margin-right: 10px;
}

ion-button#contact-button {
	width: 100%;
	margin: 0 auto;
	display: block;
	--background: #0E538F;
	margin-bottom: 10px;
}

ion-button#contact-us-button {
	width: 50%;
	margin: 0 auto;
	display: block;
	--background: #5B8E9F;
	margin-bottom: 10px;
}

ion-img#contactUsImage {
	width: 25px;
	height: 25px;
	margin-right: 10px;
}

ion-icon#contactUsImage {
	width: 25px;
	height: 25px;
	margin-right: 10px;
}

ion-button#general-button {
	width: 90%;
	margin: 0 auto;
	display: block;
	--background: #0E538F;
	margin-bottom: 10px;
}

.generalInfoContent {
	margin-top: 70px;
}


/* My Report List Styles */


/* ion-list.myReports{
    padding: 0;
    padding-bottom: 10px;
    overflow-y:auto;
    height: calc(100vh - 250px); 
  } */

ion-list.myReports>ion-item {
	--background: #ffffff10;
	border-top: 1px #ffffff30 solid;
}

ion-list.myReports ion-item:last-child {
	border-bottom: 1px #ffffff30 solid;
}

ion-list.myReports>ion-item:hover {
	--background: #ffffff20;
}

ion-list.myReports ion-item ion-grid ion-row:first-child {
	cursor: pointer;
}

ion-list.myReports .loadingContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 80px;
	animation: fadeIn;
	animation-duration: 2s;
}

ion-list.myReports .loadingContainer ion-icon {
	font-size: 4em;
}

ion-list.myReports .loadingContainer ion-icon#loading {
	font-size: 6em;
}

ion-list.myReports .loadingContainer ion-label:first-of-type {
	margin-top: 1em;
	font-size: 1.2em;
	font-family: SSENBold;
}

ion-list.myReports .loadingContainer ion-label {
	font-size: 1em;
}

ion-list.myReports .contained {
	width: 100%;
	padding: 10px;
	margin-top: 2.5px;
	margin-bottom: 2.5px;
	border-radius: 15px;
	background-color: #ffffff30 !important;
}

ion-list.myReports .contained * {
	background-color: #ffffff00;
}

ion-list.myReports ion-label {
	height: auto;
	padding: 0;
	font-family: SSENRegular;
	text-align: left;
}

ion-list.myReports ion-grid {
	padding: 0;
	margin: 0;
	padding-top: 3px;
	padding-bottom: 3px;
}

ion-list.myReports ion-row {
	margin-top: 5px;
	margin-bottom: 5px;
}

ion-list.myReports ion-col {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 0;
}

ion-list.myReports ion-row:first-child ion-col {
	align-items: center;
}

ion-list.myReports #reportTypeCol ion-icon {
	text-align: center;
	font-size: 1.7em;
	font-family: SSENBold;
	margin-bottom: 5px;
}

ion-list.myReports #reportTypeCol ion-label {
	text-align: center;
	font-size: 0.7em;
	font-family: SSENBold;
}

ion-list.myReports #reportDetailsCol ion-label {
	align-self: flex-start;
	text-align: left;
	margin-left: 10px;
	margin-right: 10px;
	font-size: 0.9em;
	font-family: SSENBold;
	width: 190px;
}

ion-list.myReports #reportDetailsCol ion-label:last-child {
	font-size: 0.8em;
	font-family: SSENRegular;
}

ion-list.myReports ion-row.expandedSection.collapsed {
	display: none;
}

ion-list.myReports ion-label.title {
	font-size: 1em;
	font-family: SSENBold;
	text-decoration: underline;
	text-align: left;
}

ion-list.myReports ion-label.large {
	font-size: 0.9em;
	font-family: SSENBold;
	margin-left: 8px;
	margin-top: 5px;
}

ion-list.myReports ion-label.medium {
	font-size: 0.9em;
	font-family: SSENRegular;
	margin-left: 30px;
	white-space: normal;
}

ion-list.myReports ion-label.ion-text-wrap {
	text-align: left;
	padding-top: 10px;
	padding-right: 0px;
	padding-bottom: 10px;
	padding-left: 15px;
	margin: 0;
	--ion-font-family: SSENRegular;
	font-size: 1em;
}

ion-list.myReports .message ion-label.ion-text-wrap {
	padding-right: 10px;
	padding-left: 15px;
}

ion-list.myReports ion-label.small {
	font-size: 0.8em;
	font-family: SSENRegular;
	margin-left: 14px;
}

ion-list.myReports a {
	color: white;
}


/* ion-list.myReport .location{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;
  } */

div.location {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-top: 5px;
}

div.location ion-icon {
	/* font-size: 1em; */
	margin-right: 5px;
	margin-top: 5px;
}

div.message {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 3px 10px 3px 0;
	background-color: #1d5488 !important;
	border-radius: 0 0 12px 12px;
}

div.message ion-icon {
	/* font-size: 1em; */
	margin-right: 5px;
	margin-top: 5px;
}

div.date {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-top: 5px;
	font-size: 0.8em;
	font-weight: bold;
	padding: 3px 0px 3px 0;
	background-color: var(--ion-color-primary) !important;
	border-radius: 3px 12px 0 0;
}

div.date>ion-label {
	margin-left: 15px;
}

div.type {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-top: -10px;
}

div.type ion-icon {
	margin-right: 5px;
	margin-top: 10px;
}

ion-toggle#nRequest {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-left: 20px;
	margin-bottom: -10px;
}


/* ion-list.myReport .location ion-icon{
    font-size: 1.5em;
    margin-right: 5px;
  } */

ion-button#binButton {
	height: 30px;
	width: 30px;
	font-size: 10px;
}

ion-icon.bin {
	color: white;
	background-color: var(--ion-color-primary);
}

.reportPhotoImg::part(image) {
	border-radius: 10px;
}


/* #photoGrid .reportPhotoImg::part(image) {
    width: 400px;
  } */

ion-icon {
	pointer-events: none;
}

.hidden {
	display: none !important;
}

ion-list.dataUnavailable * {
	text-align: center;
	padding-left: 20px;
	padding-right: 20px;
}

ion-list.dataUnavailable ion-icon#loading {
	height: 100px;
	width: 100px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 40px;
	display: block;
}

ion-list.dataUnavailable ion-icon#offline {
	height: 60px;
	width: 60px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 40px;
	display: block;
}

ion-toast {
	font-family: SSENRegular;
}

ion-toast::part(button) {
	font-family: SSENRegular !important;
}

.sidebarToast {
	transform: translateY(-85px) translateX(-175px);
	--max-width: 350px;
	--background: #104567;
	--border-radius: 20px;
	--border-color: var(--ion-color-primary);
	--border-style: solid;
	--border-width: 2px;
}

@media only screen and (max-width: 940px) {
	.sidebarToast {
		transform: translateY(-85px) translateX(-175px);
	}
}

@media only screen and (max-width: 810px) {
	.sidebarToast {
		transform: translateY(-60px) translateX(-175px);
	}
}

@media only screen and (max-width: 720px) {
	.sidebarToast {
		--max-width: 250px;
	}
}

@media only screen and (max-width: 640px) {
	.sidebarToast {
		transform: translateY(-35px);
		--max-width: none;
		--start: 60px;
		--end: 50px;
	}

	.noCookies ion-list#info {
		padding-bottom: 0;
	}

	.noCookies ion-list.evList {
		padding-bottom: 0;
	}

	.noCookies .contentScroll {
		padding-bottom: 20px;
	}
}

@media only screen and (max-height: 500px) and (max-width: 640px) {

	.content,
	.contentScroll {
		height: calc(100% - 140px) !important;
	}
}

@media only screen and (min-width: 641px) {
	.hideOnDesktop {
		display: none;
	}
}

@media only screen and (max-width: 640px) {
	.splitRight {
		position: absolute;
		height: 100%;
		width: 100%;
		top: unset;
		bottom: 0;
		right: 0;
		background-color: #003E66;
		z-index: 999;
		transition: 0.5s;
	}

	.splitRight.closed {
		height: 0%;
	}

	.content,
	.contentScroll {
		height: calc(100% - 180px);
	}

	.modal-wrapper {
		margin: 15px;
	}

	ion-grid.dataList>ion-row:hover {
		background-color: #ffffff20;
		padding: 0px 10px;
	}

	ion-list.evList .contained:last-of-type {
		margin-bottom: 10px;
	}

	#applyFilters {
		margin: 10px 70px 10px 70px;
	}
}


/* @media only screen and (max-height: 902px) {
    div#videoPlayer{
      width: 100%;
      margin:0 auto;
      display:block;
      margin-top: -55px;
    }
  }
  
  @media only screen and (max-height: 760px) {
    div#videoPlayer{
      width: 100%;
      margin:0 auto;
      display:block;
      margin-top: -60px;
    }
  }
  
  @media only screen and (max-width: 540px) {
    div#videoPlayer{
      width: 100%;
      margin:0 auto;
      display:block;
      margin-top: -20px;
    }
  }
  
  @media only screen and (max-width: 414px) {
    div#videoPlayer{
      width: 100%;
      margin:0 auto;
      display:block;
      margin-top: -60px;
    }
  }
  
  @media only screen and (max-width: 390px) {
    div#videoPlayer{
      width: 100%;
      margin:0 auto;
      display:block;
      margin-top: -65px;
    }
  }
  
  @media only screen and (max-height: 650px) {
    div#videoPlayer{
      width: 100%;
      margin:0 auto;
      display:block;
      margin-top: -70px;
    }
  } */

@media only screen and (max-width: 810px) {
	.startButton {
		position: fixed;
		bottom: unset;
		left: unset;
		margin-left: unset;
		top: 18px;
		right: 10px;
		z-index: 99;
	}

	.ios-startButton {
		position: fixed;
		bottom: unset;
		left: unset;
		margin-left: unset;
		top: 27px;
		right: 10px;
		z-index: 99;
		display: block;
	}

	.ios-startButton ion-button {
		width: 140px;
	}

	.ios-startButton ion-button ion-label {
		padding-left: 8px;
	}

	.startButton ion-button {
		width: 140px;
	}

	.startButton ion-button ion-label {
		padding-left: 8px;
	}

	.startButton.startButtonSmall {
		display: block;
	}

	.startButton.startButtonLarge {
		display: none;
	}
}

@media only screen and (max-height: 500px) {
	.startButton {
		position: fixed;
		bottom: unset;
		left: unset;
		margin-left: unset;
		top: 18px;
		right: 10px;
		z-index: 99;
	}

	.ios-startButton {
		position: fixed;
		bottom: unset;
		left: unset;
		margin-left: unset;
		top: 30px;
		right: 10px;
		z-index: 99;
		display: block;
	}

	.ios-startButton ion-button {
		width: 140px;
	}

	.ios-startButton ion-button ion-label {
		padding-left: 8px;
	}

	.startButton ion-button {
		width: 140px;
	}

	.startButton ion-button ion-label {
		padding-left: 8px;
	}

	.startButton.startButtonSmall {
		display: block;
	}

	.startButton.startButtonLarge {
		display: none;
	}

	.header {
		height: 140px;
	}

	.icon {
		visibility: hidden;
		height: 0;
	}

	.iconWithButtons {
		visibility: hidden;
		height: 0;
	}

	.filterIconWithButtons {
		visibility: hidden;
		height: 25px;
	}

	.title {
		margin-top: 33px;
		margin-bottom: 3px;
	}

	ion-grid#headerGrid ion-row {
		height: 20px;
	}

	.content,
	.contentScroll {
		height: calc(100% - 210px);
	}

	/* div#videoPlayer{
      width: 100%;
      margin:0 auto;
      display:block;
      margin-top: -85px;
    } */
	.generalInfoContent {
		margin-top: 30px;
	}
}

@media only screen and (max-width: 340px) {
	div#evFilterContent .multiLine {
		height: auto;
		white-space: normal;
	}
}

@media only screen and (max-width: 330px) {
	.listHeader #gspTitle {
		display: none;
	}

	/* div#videoPlayer{
      width: 100%;
      margin:0 auto;
      display:block;
      margin-top: -85px;
    } */
	div#evFilterContent .multiLine {
		height: auto;
		white-space: normal;
	}
}

@media only screen and (max-width: 320px) {
	ion-label#showEvsInOutage {
		font-size: 14px;
	}
}

@media only screen and (max-width: 280px) {

	/* div#videoPlayer{
      width: 100%;
      margin:0 auto;
      display:block;
      margin-top: -95px;
    } */
	ion-label#showEvsInOutage {
		font-size: 12px;
	}
}

@supports (-webkit-touch-callout: none) {
	/* ion-list#info,.contentScroll,ion-list.evList {
      padding-bottom: 120px;
    } */
}